module.exports = [{
      plugin: require('/Users/randy/Code/Sites/randleunger.com/node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/randy/Code/Sites/randleunger.com/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-135308681-2"},
    },{
      plugin: require('/Users/randy/Code/Sites/randleunger.com/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/randy/Code/Sites/randleunger.com/node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
